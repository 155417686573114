import React, {useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sanityClient from "@sanity/client";

function ServicesItemPart() {
        const [services, setServices] = useState([])
        useEffect(() => {
          const clientSanity = sanityClient({
            projectId: 'qepg5qgt',
            dataset: 'production',
            token: 'sk76atFvpYxjQnBkaRIKowotNKfGqbDYlkNQqSe2R0dkX6TbMoKk1aCgKFUTtZJdug6LcS7d5RCOVKN2lrLMcaZMcLtwAspgpZu3qAK9V7IkkTk8UOZCW2gHnm6UuRC0u3oEUrLqwQbQzy560oggKrIYVg7hHuxyD3wO2A0JSulcezZfikWs', // or leave blank for unauthenticated usage
            useCdn: true, // `false` if you want to ensure fresh data
          })
          // const query = '*[_type == "product"] {title, description, "image":image.asset->url,service->{titulo}, features}'
          const params = {minSeats: 2}

          const query2 = `
                *[_type == "service"] {
                    _id, 
                    title, 
                    description, 
                    "products" : *[_type == "product" && references(^._id)] | order(order)
                        {title, description, "image": image.asset->url, features} 
            }`

          clientSanity.fetch(query2, params).then((services) => {
              setServices(services)
              console.log(services);
          })
        }, [])
        return ( 
          <div className="services-item-area pb-50">
            <Container>
                {
                    services.map(service => (
                    <Row className="justify-content-center service-list" key={service._id}>
                        <Col lg="12" md="12" sm="12">
                            <h4 className="title"><span style={{color: '#ec7a24e0'}}> PLANES -</span> {service.title} </h4>
                        </Col>
                        {
                            service.products.map(product => (
                                <Col lg="4" md="6" sm="8" className="card-service">
                                    <div className="single-services-item mt-30">
                                        <img src={product.image} alt="" />
                                        <div className="services-overlay">
                                            {/*<i className="fal fa-eye-dropper" />*/}
                                            <h4 className="title">{product.title}</h4>
                                            {product.service?.titulo && <h6 className="text-2">{product.service?.titulo}</h6>}
                                            <p>
                                                {product.description?.length > 120 ? product.description.substring(0, 120) : product.description} {product.description.length > 120 && '...'}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                  ))
                }

                {/*<Col lg="4" md="6" sm="8">*/}
                {/*  <div className="single-services-item mt-30">*/}
                {/*    <img src="assets/images/services-thumb-2.jpg" alt="" />*/}
                {/*    <div className="services-overlay">*/}
                {/*      <i className="fal fa-gavel" />*/}
                {/*      <h4 className="title">IT Consultancy</h4>*/}
                {/*      <p>*/}
                {/*        The functional goal of technical content is to help people use a*/}
                {/*        product successfully. The business goal must tie the content.*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  /!* single services *!/*/}
                {/*</Col>*/}
                {/*<Col lg="4" md="6" sm="8">*/}
                {/*  <div className="single-services-item mt-30">*/}
                {/*    <img src="assets/images/services-thumb-3.jpg" alt="" />*/}
                {/*    <div className="services-overlay">*/}
                {/*      <i className="fal fa-map-marked-alt" />*/}
                {/*      <h4 className="title">Technology Prof.</h4>*/}
                {/*      <p>*/}
                {/*        The functional goal of technical content is to help people use a*/}
                {/*        product successfully. The business goal must tie the content.*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  /!* single services *!/*/}
                {/*</Col>*/}
                {/*<Col lg="4" md="6" sm="8">*/}
                {/*  <div className="single-services-item mt-30">*/}
                {/*    <img src="assets/images/services-thumb-5.jpg" alt="" />*/}
                {/*    <div className="services-overlay">*/}
                {/*      <i className="fal fa-hurricane" />*/}
                {/*      <h4 className="title">Web Development</h4>*/}
                {/*      <p>*/}
                {/*        The functional goal of technical content is to help people use a*/}
                {/*        product successfully. The business goal must tie the content.*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  /!* single services *!/*/}
                {/*</Col>*/}
                {/*<Col lg="4" md="6" sm="8">*/}
                {/*  <div className="single-services-item mt-30">*/}
                {/*    <img src="assets/images/services-thumb-4.jpg" alt="" />*/}
                {/*    <div className="services-overlay">*/}
                {/*      <i className="fal fa-bezier-curve" />*/}
                {/*      <h4 className="title">App Development</h4>*/}
                {/*      <p>*/}
                {/*        The functional goal of technical content is to help people use a*/}
                {/*        product successfully. The business goal must tie the content.*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  /!* single services *!/*/}
                {/*</Col>*/}
                {/*<Col lg="4" md="6" sm="8">*/}
                {/*  <div className="single-services-item mt-30">*/}
                {/*    <img src="assets/images/services-thumb-6.jpg" alt="" />*/}
                {/*    <div className="services-overlay">*/}
                {/*      <i className="fal fa-umbrella" />*/}
                {/*      <h4 className="title">Game Design</h4>*/}
                {/*      <p>*/}
                {/*        The functional goal of technical content is to help people use a*/}
                {/*        product successfully. The business goal must tie the content.*/}
                {/*      </p>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  /!* single services *!/*/}
                {/*</Col>*/}
            </Container>
            {/* container */}
          </div>
         );
}
 
export default ServicesItemPart;
