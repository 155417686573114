import Layout from "../../layouts";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import "./style.scss";

export function SpeedTestPage(){
    return (
        <Layout>
            <div className="page-title-area">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="page-title-item text-center">
                                <h2 className="title">Test de velocidad</h2>
                                <nav aria-label="breadcrumb">


                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link  to={"/"}>Inicio </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Test de velocidad
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            {/* page title */}
                        </Col>
                    </Row>
                    {/* row */}
                </Container>
                {/* container */}
            </div>
            <Container>
                <Row>
                    <Col>
                        <iframe title="speed-test"  className="speed-test" src="//www.metercustom.net/plugin/?hl=es&th=w"/>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}
