import React from 'react';
import { NavLink } from "react-router-dom";
import { Nav } from 'react-bootstrap';

class PartialNav extends React.Component {
    render() {
        return (
            <>
                <Nav className="mr-auto">
                    <li className="nav-item">
                        <NavLink className="nav-link" to={'/'} exact activeClassName="active-link">Inicio</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={'/about'} activeClassName="active-link">Nuestra empresa</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={'/services'} activeClassName="active-link">Servicios</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={'/pqr'} activeClassName="active-link">Marco Legal</NavLink>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to={'/pqr'} activeClassName="active-link">PQR</NavLink>
                            </li>
                            <li>
                                <NavLink to={'/recomendaciones-de-uso'} activeClassName="active-link">Recomendaciones De Uso</NavLink>
                            </li>
                            <li>
                                <NavLink to={'/bloqueo-de-paginas'} activeClassName="active-link">Denuncias Bloqueos De Paginas</NavLink>
                            </li>
                            <li>
                                <NavLink to={'/su-seguridad'} activeClassName="active-link">Su Seguridad</NavLink>
                            </li>
                            <li>
                                <NavLink to={'/ley-679'} activeClassName="active-link">Ley 679 De 2001 </NavLink>
                            </li>
                            <li>
                                <a href="https://www.crcom.gov.co/es/pagina/regimen-proteccion-usuario" target="_blank" rel="noopener noreferrer">Conoce
                                    Tus Derechos</a>
                            </li>
                            <li>
                                <NavLink to={'/control-parental'} activeClassName="active-link">Control Parental</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={'/contact'} activeClassName="active-link">Contactenos</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={'/paga-tu-factura'} activeClassName="active-link">Paga tu Factura</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={'/test-velocidad'} activeClassName="active-link">Test de Velocidad</NavLink>
                    </li>

                </Nav>
            </>
        );
    }
}
 
export default PartialNav;
