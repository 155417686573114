import React from "react";

//styles
import "./style.scss"

export function IcomButton({ label }) {
    return (
        <button className="icom-button">
            {label}
        </button>
    )
}
