import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';

class ContactPage extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  // componentDidMount() {
	// 	setTimeout(function () {
	// 		document.querySelector(".preloader").style = "display: none";
	// 	}, 5000);
  // }
  openModal = event => {
    this.setState({isOpen: true});
    event.preventDefault();
  }

  formHandle = event => {
    toast.success("Thank you for contact with us!");
    event.preventDefault();
  }

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">Contactanos</h2>
                  <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Inicio </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Contactanos
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }
        <div>
          {/*====== CONTACT DETAILS PART START ======*/}
          <div className="contact-details-area pt-50 pb-120">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="contact-info mr-30 mt-30">
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-phone" />
                        <h5 className="title">Número de teléfono</h5>
                        <p>300 469 3986</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-envelope" />
                        <h5 className="title">Correo electrónico</h5>
                        <p>icomredes@gmail.com</p>
                      </div>
                    </div>
                    <div className="contact-item-1">
                      <div className="contact-info-item text-center">
                        <i className="fal fa-map" />
                        <h5 className="title">Dirección Oficina</h5>
                        <p>Barrio los cerezos Mz E1 lote 22</p>
                      </div>
                      <div className="contact-info-item text-center">
                        <i className="fal fa-globe" />
                        <h5 className="title">Red social</h5>
                        <a style={{color: '#fd7e14'}} href="https://www.instagram.com/icomredes/" target="_blank" rel="noopener noreferrer" >@icomredes</a>
                      </div>
                    </div>
                  </div>
                  {/* contact info */}
                </Col>
                <Col lg="6">
                  <div className="map-area mt-30">
                    <iframe
                      src="https://maps.google.com/maps?q=barrio%20los%20cerezos%20cartagena&t=k&z=17&ie=UTF8&iwloc=&output=embed"
                      style={{ border: 0 }}
                      allowFullScreen
                      width={600}
                      height={470}
                      title="Our Localtion"
                    />
                  </div>
                  {/* map area */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
        </div>
        </Layout>
      );
    }
}

export default ContactPage;
