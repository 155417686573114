export default [
    {
        value: 'presentacion-del-servicio',
        label: 'Presentación del servicio',
        options: [
            'Modificación condiciones acordadas',
            'Publicidad engañosa',
            'Servicios no solicitados',
            'Fraudes en contratación',
            'Datos personales',
        ]
    },
    {
        value: 'terminacion-del-contrato',
        label: 'Terminación del contrato',
        options: [
            'Imposibilidad terminación contrato',
            'Cláusula de permanencia sin consentimiento',
            'Cláusula de permanencia superior a 1 año',
            'Falta de información',
        ]
    },
    {
        value: 'roaming-internacional',
        label: 'Roaming Internacional',
        options: [
            'Falta de información',
            'No controles de consumo',
            'Facturación',
            'Calidad del servicio',
        ]
    },
    {
        value: 'calidad-del-servicio',
        label: 'Calidad del servicio',
        options: [
            'No disponibilidad del servicio',
            'Caída de llamadas',
            'No compensación informada',
            'Intermitencia',
            'No traslado a nuevo domicilio',
        ]
    },
    {
        value: 'facturacion',
        label: 'Facturación',
        options: [
            'Error factura/ Cobro o descuento injustificado',
            'Incremento tarifario',
            'Reporte a centrales de riesgo',
            'Cobro en proceso de reclamación',
            'Fraude en facturación',
            'Cobro por reconexión'
        ]
    },
    {
        value: 'medios-de-atencion-al-usuario',
        label: 'Medios de atención al usuario',
        options: [
            'Medios de atención al usuario',
        ]
    },
    {
        value: 'otros',
        label: 'Otros',
        options: [
            'otros'
        ]
    },
]
