import Layout from "../../layouts";
import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import './style.scss'

export default function YourSecurity(){
    return  <Layout>
        <div className="page-title-area">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="page-title-item text-center">
                            <h2 className="title">Su Seguridad</h2>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="container pb-120 pt-120">
                <p>Seguridad</p>
                <p><strong>PHISHING:</strong></p>
                <p>Definición:</p>
                <p>El “phishing” es una modalidad de estafa diseñada con la finalidad de robarle al usuario su
                    identidad. El delito consiste en obtener información tal como números de tarjetas de crédito,
                    contraseñas, información de cuentas u otros datos personales por medio de engaños. Este tipo de
                    fraude se recibe habitualmente a través de mensajes de correo electrónico o de ventanas
                    emergentes.</p>
                <p>Como funciona:</p>
                <p>En esta modalidad de fraude, el usuario malintencionado envía millones de mensajes falsos que parecen
                    provenir de sitios Web reconocidos o de su confianza, como un banco o la empresa de su tarjeta de
                    crédito. Dado que los mensajes y los sitios Web que envían estos usuarios parecen oficiales, logran
                    engañar a muchas personas haciéndoles creer que son legítimos. La gente confiada normalmente
                    responde a estas solicitudes de correo electrónico con sus números de tarjeta de crédito,
                    contraseñas, información de cuentas u otros datos personales.</p>
                <p>Para que estos mensajes parezcan aún más reales, el estafador suele incluir un vínculo (link) falso
                    que parece dirigir al sitio Web legítimo, pero en realidad lleva a un sitio falso o incluso a una
                    ventana emergente que tiene exactamente el mismo aspecto que el sitio Web oficial. Estas copias se
                    denominan “sitios Web piratas”. Una vez que el usuario está en uno de estos sitios Web, introduce
                    información personal sin saber que se transmitirá directamente al delincuente, que la utilizará para
                    realizar compras, solicitar una nueva tarjeta de crédito o robar su identidad.</p>
                <p>Como Protegerse:</p>
                <p>Este tipo de fraude debe contenerse a través del ISP y vía usuario.</p>
                <p>El usuario debe seguir estas recomendaciones para evitar que sea víctima de robo de su identidad:</p>
                <p>Nunca responda a solicitudes de información personal a través de correo electrónico. Si tiene alguna
                    duda, póngase en contacto con la entidad que supuestamente le ha enviado el mensaje. Tener especial
                    cuidado en correos que supuestamente han sido enviados por entidades financieras y compras por
                    Internet, como eBay, PayPal, bancos, etc. Solicitando actualizar datos de cuentas y/o accesos, ya
                    que ninguna de estas entidades solicitan este tipo de información por este medio.</p>
                <p>Asegúrese que su PC cuente con las últimas actualizaciones a nivel de seguridad dadas por los
                    fabricantes (Microsoft, Mac, etc…)</p>
                <p>Para visitar sitios Web, introduzca directamente la dirección URL en la barra de direcciones.</p>
                <p>Asegúrese de que el sitio Web utiliza cifrado.</p>
                <p>Si tiene instalado servidores Web, asegúrese que tanto el aplicativo como el sistema operativo
                    cuenten con las últimas actualizaciones a nivel de seguridad dadas por los fabricantes
                    correspondientes. Muchas veces los phishers buscan en la red servidores Web vulnerables que puedan
                    ser utilizados para montar páginas que intentan suplantar la identidad de una entidad financiera,
                    sin que el usuario se de cuenta. Para el cliente, esto tiene como repercusión la afectación directa
                    en su servicio de Internet, ya que la IP donde se encuentra alojada la página fraude es reportada
                    por entidades internacionales pidiendo al ISP (Comcel S.A.) el bloqueo de la misma.</p>
                <p>Comunique los posibles delitos relacionados con su información personal a las autoridades
                    competentes.</p>
                <p>A nivel del ISP, actualmente Comcel S.A. implementa filtros anti-spam que ayudan a proteger a los
                    usuarios de los phishers, ya que reducen el número de correos electrónicos relacionados con el
                    phishing recibidos por el usuario.</p>
                <p><strong>SPAM</strong></p>
                <p>Definición:</p>
                <p>Se llama spam, correo basura a los mensajes no solicitados, habitualmente de tipo publicitario,
                    enviados en cantidades masivas que perjudican de una u otra manera a los usuarios que reciben este
                    correo. Aunque su difusión se puede hacerse por distintas vías, lo mas común es hacerlo vía correo
                    electrónico.</p>
                <p>Actualmente Comcel S.A. cuenta con una plataforma que protege a los usuarios de este tipo de
                    correos</p>
                <p>Normas básicas para evitar y reducir al mínimo el spam</p>
                <p>El spam es un problema que debe ser controlado desde diferentes frentes, tanto a nivel de usuarios
                    como a nivel de los proveedores de Internet.</p>
                <p>A nivel de usuario, se pueden seguir estas recomendaciones para evitar ser inundado por correo
                    spam:</p>
                <p>Si no se reconoce un remitente de un correo, no abrir los archivos adjuntos del mensaje, incluso si
                    usted tiene un software bloqueador de spam y/o filtro de aplicación ejecutándose en su PC. Los
                    archivos adjuntos a menudo incluyen software o aplicaciones malintencionadas que pueden tener
                    efectos muy negativos sobre su PC, desde borrar su información mas valiosa hasta capturar
                    contraseñas, números de tarjetas de crédito, etc… sin que el usuario ni siquiera se entere. Estas
                    aplicaciones no se pueden incluir en un mensaje de correo electrónico en texto plano, la cual es la
                    razón por la que se empaquetan en los archivos adjuntos.</p>
                <p>Si recibe un correo spam, nunca haga clic en el vínculo “Quitar spam”, ya que lo que buscan los
                    spammers es que el cliente verifique que esta dirección de correo está activa, añadiendo
                    posiblemente su cuenta de correo a más y más listas de spam, lo cual ocasionará que usted reciba
                    mayor cantidad de correo no deseado.</p>
                <p>Algunos programas que utilizan los spammers tratan de adivinar las cuentas de correo a las cuales
                    enviar correo no deseado, por lo cual es recomendable utilizar cuentas que contengas números y
                    letras para que no sean fácilmente ubicadas.</p>
                <p>Nunca dar click sobre enlaces (links) que se encuentren dentro de un mensaje de correo electrónico de
                    un remitente desconocido. Probablemente pueda ser un caso de phishing para tratar de robar la
                    identidad del usuario o puede activar un programa que silenciosamente descargue aplicaciones en su
                    PC.</p>
                <p>En caso de que usted conozca al remitente, igual la recomendación es no dar click sobre enlaces
                    (links) que se encuentren dentro del mensaje. Uno nunca puede estar seguro de que quien envía el
                    mensaje es realmente quien dice ser, ya que los spammers pueden cambiar la cuenta remitente,
                    suplantando la identidad de otra persona.</p>
                <p>Para acceder a un enlace (link) dentro del mensaje, se recomienda cerrar el mensaje, y visitar el
                    sitio en cuestión, introduciendo manualmente la URL (por ejemplo, www.google.com) en su navegador de
                    Internet. Es la única manera de estar seguro que la página a la cual se está accediendo es la
                    real.</p>
                <p>Para tratar de evitar que su cuenta sea ingresada en listas de correo utilizadas por los spammers, se
                    recomienda que el usuario preste cuidado a los sitios donde ingresa y que le solicita registrarse
                    (mediante una cuenta de correo), ya que existen muchos sitios Web inescrupulosos que venden estas
                    cuentas registradas a redes de spammers.</p>
                <p>Si tiene instalado servidores de correo, asegúrese que tanto el aplicativo como el sistema operativo
                    cuenten con las últimas actualizaciones a nivel de seguridad dadas por los fabricantes
                    correspondientes. En muchos casos, los servidores de correo, debido a configuraciones deficientes,
                    permiten que cualquier persona, desde Internet, utilice estos servidores para enviar correos
                    (conocido como Open Relay), afectando el servicio de correo del cliente y muy posiblemente será
                    bloqueado en listas negras de Spam mantenidas a nivel mundial.</p>
                <p>En caso que ud como cliente tenga problemas en el envío de correos, para verificar que su IP no se
                    encuentra reportada en listas negras de spam, puede revisar los siguientes enlaces para realizar la
                    consulta:</p>
                <p><span style={{color: '#ec7a24'}}>http://www.dnsstuff.com/</span></p>
                <p>En caso que su IP se encuentre reportada acceder al siguiente enlace para tramitar el desbloqueo:</p>
                <p>http://200.118.2.73/varios/bloqueoIPs.asp</p>
                <p>Para que pueda ser efectivo este desbloqueo, el cliente deberá tomar las medidas correspondientes
                    para evitar que se continúe enviando correo spam.</p>
                <p>Hay que tener en cuenta que el tiempo de desbloqueo depende del sitio en el cual ha sido reportado
                    una IP. Entre los sitios más frecuentes, están:</p>
                <p>www.aol.com: Tiempo de desbloqueo aprox. 48 horas</p>
                <p>www.lashback.com: Tiempo de desbloqueo aprox. 1 hora</p>
                <p>www.uceprotect.net: Tiempo de desbloqueo aprox. 7 días</p>
                <p>www.spamcop.net: Tiempo de desbloqueo aprox. 24 horas</p>
                <p>www.dsbl.org: Tiempo de desbloqueo aprox. 7 días</p>
                <p>WWW.WPBL.INFO: Tiempo de desbloqueo aprox. 1 hora</p>
                <p>WWW.MOENSTED.DK: Tiempo de desbloqueo aprox. 1 hora</p>
                <p>www.comcast.com: Tiempo de desbloqueo aprox. 48 horas</p>
                <p>www.abuso.cantv.net: Tiempo de desbloqueo aprox.48 horas</p>
                <p>www.spamhaus.org: Tiempo de desbloqueo aprox. 24 horas</p>
                <p><strong>VIRUS</strong></p>
                <p>Definición:</p>
                <p>Un virus informático es un programa que se copia automáticamente y que tiene por objeto alterar el
                    normal funcionamiento del PC, sin el permiso o el conocimiento del usuario. Los virus pueden
                    destruir, de manera intencionada, los datos almacenados en un PC aunque también existen otros más
                    “benignos”, que solo se caracterizan por ser molestos.</p>
                <p>Los virus informáticos tienen, básicamente, la función de propagarse, replicándose, pero algunos
                    contienen además una carga dañina (payload) con distintos objetivos, desde una simple broma hasta
                    realizar daños importantes en los sistemas, o bloquear las redes informáticas generando tráfico
                    inútil.</p>
                <p>Como Protegerse:</p>
                <p>Similar al spam, los virus son un problema que debe ser controlado desde diferentes frentes, tanto a
                    nivel de usuarios como a nivel de los proveedores de Internet.</p>
                <p>A nivel de usuario, se pueden seguir estas recomendaciones para evitar ser víctima de los efectos de
                    un virus informático:</p>
                <p>Si no se reconoce un remitente de un correo, no abrir los archivos adjuntos del mensaje, incluso si
                    usted tiene un software antivirus y/o filtro de aplicación ejecutándose en su PC. Los archivos
                    adjuntos a menudo incluyen software o aplicaciones malintencionadas que pueden tener efectos muy
                    negativos sobre su PC. Evite caer en técnicas conocidas como de Ingeniería social en la cual llega
                    un correo electrónico con un mensaje del estilo “ejecute este programa y gane un premio”.</p>
                <p>Evitar la instalación de software pirata o de baja calidad, mediante la utilización de redes P2P, ya
                    que muchas veces, existen ciertos sitios que “prometen” la descarga de un aplicativo en particular
                    pero en realidad lo que el usuario descarga es un virus.</p>
                <p>Asegurarse que su equipo PC cuente con las últimas actualizaciones a nivel de seguridad tanto a nivel
                    de sistema operativo como de los aplicativos instalados, dadas por el fabricante. Existen algunos
                    tipos de virus que se propagan sin la intervención de los clientes y que aprovechan debilidades de
                    seguridad de los diferentes sistemas y aplicaciones, como por ejemplo los virus Blaster y
                    Sasser.</p>
                <p>Instalar software antivirus en el PC, el cual esté actualizado con las últimas firmas dadas por el
                    fabricante respectivo.</p>
                <p>A nivel de ISP, BETEL SOLUCIONES S.A.S. cuenta actualmente con equipos especializados en la detección
                    y filtrado de correos con virus, mediante filtros de tipo heurístico, firmas de virus reconocidos y
                    adicional cuenta con filtros de tipo preventivo, que aunque a nivel público no se halla liberado una
                    firma para contener una nueva amenaza, el sistema coloca en cuarentena este tipo de tráfico, hasta
                    determinar si el tráfico es legal o hasta que se tenga la firma correspondiente a la propagación del
                    nuevo virus o gusano. Todos los correos que los usuarios reciben y envían son filtrados por esta
                    herramienta.</p>
        </div>
    </Layout>
}
