import Layout from "../../layouts";
import {Accordion, Card, Col, Container, Row} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

import TeprotejoImg from '../../assets/images/icomredes/teprot.jpeg'
import PantallasAmigas from '../../assets/images/icomredes/pantallas-amigas.png'


function BlockPages() {
    return (
        <Layout>
            <div className="page-title-area">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="page-title-item text-center">
                                <h2 className="title">Denuncias bloqueos de paginas</h2>
                                <nav aria-label="breadcrumb">

                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link  to={"/"}>Inicio </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Denuncias bloqueos de paginas
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            {/* page title */}
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <div className="about-faq-area about-faq-area-page pb-100">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="faq-accordion-3 mt-30">
                                    <Accordion>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Link} variant="link" eventKey="0">
                                                    <i className="fal fa-magic" />
                                                    FISCALÍA GENERAL DE LA NACIÓN
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Teléfono: 01 800 0912280</li>
                                                        <li>Email: contacto@fiscalia.gov.co</li>
                                                        <li><a href="http://www.fiscalia.gov.co/" target="_blank" rel="noopener noreferrer">Sitio web</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* card */}
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Link} variant="link" eventKey="1">
                                                    <i className="fal fa-magic" />
                                                    MINISTERIO DE LAS TIC
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Teléfono: 01 800 0912667</li>
                                                        <li><a href="http://www.enticconfio.gov.co" target="_blank" rel="noopener noreferrer">Sitio web</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* card */}
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Link} variant="link" eventKey="2">
                                                    <i className="fal fa-magic" />
                                                    DIJIN GRUPO INVESTIGATIVO DELITOS INFORMÁTICOS
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Teléfono: PBX: 426 6900 Ext. 6301-6302</li>
                                                        <li>Carrera 77A # 45-61 Barrio Modelia</li>
                                                        <li><a href="https://www.policia.gov.co/dijin" target="_blank" rel="noopener noreferrer">Sitio web</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* card */}
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Link} variant="link" eventKey="3">
                                                    <i className="fal fa-magic" />
                                                    INSTITUTO COLOMBIANO DE BIENESTAR FAMILIAR
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Teléfono: 01 8000 918080</li>
                                                        <li>Línea 141</li>
                                                        <li><a href="https://www.icbf.gov.co" target="_blank" rel="noopener noreferrer">Sitio web</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* card */}
                                        <Card>
                                            <Card.Header id="headingFive">
                                                <Accordion.Toggle as={Link} variant="link" eventKey="4">
                                                    <i className="fal fa-magic" />
                                                    PERSONERÍA
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="4">
                                                <Card.Body>
                                                    <ul>
                                                        <li>Línea 143</li>
                                                        <li><a href="http://www.personeriabogota.gov.co/atencion-al-ciudadano/peticiones-y-qrsd" target="_blank" rel="noopener noreferrer">Sitio web</a></li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* card */}
                                    </Accordion>
                                </div>
                                {/* faq accordion */}
                            </Col>
                            <Col lg="6" style={{textAlign: 'center'}}>
                                <a href="https://www.pantallasamigas.net/"  target="_blank" rel="noopener noreferrer">
                                    <div>
                                        <img src={PantallasAmigas} alt="faq" />
                                    </div>
                                </a>
                                <a href="https://www.teprotejo.org/"  target="_blank" rel="noopener noreferrer">
                                    <div>
                                        <img src={TeprotejoImg} alt="faq" />
                                    </div>
                                </a>
                            </Col>
                        </Row>
                        {/* row */}
                    </Container>
                    {/* container */}
                </div>
            </div>

        </Layout>
    )
}

export default BlockPages;
