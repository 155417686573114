import Layout from "../../layouts";
import './index.scss'

import {Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useRef, useState} from "react";

import TypesRequirement from '../../data/type-requirement'

import emailjs from 'emailjs-com';


export function PQRPage(){

    const [data, setData] = useState({
        personType: 'natural',
        reasonRequest: 'peticion',
        typeRequirement: 'presentacion-del-servicio',
        tematic: 'Modificación condiciones acordadas'
    })
    const refFile = useRef(null);

    const  handleValuesForm = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setData((prevProps) => ({...prevProps, [name]: value}))
       if(name === 'typeRequirement') {
           const typesByRequirement = getTypesByRequirementByValue(value)
           setData((prevProps) => ({...prevProps, 'tematic': typesByRequirement.options[0]}))
       }
   }

   const handleFile =  async () => {
       return await getBase64(refFile.current.files[0]);
   }

    const handlePQR =  async  (e) => {
        e.preventDefault();
        await handleFile();

        const  today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        data['date'] = mm + '/' + dd + '/' + yyyy;
        data['quarter'] = Math.floor((today.getMonth() + 3) / 3);
        data['year'] = yyyy;
        data['month'] = mm;
        // data['file'] =  `<a href="${file}" >Aqui adasdasdas</a>`
        console.log(data);
        try {
            const result = await emailjs.send('eduardotewar', 'template_otx0l9s', data , 'user_zktUex8Q1k8SlwWnQFKyb')
            console.log('the email was send', result);
        } catch (e) {
            console.error('error when send email', e);
        }
    }

    const getTypesByRequirementByValue = (value) => {
        return TypesRequirement.find(type => type.value === value)
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result)
            };
            reader.onerror = function (error) {
                reject(error);
            };
        })
    }

    const renderInfoPerson = () => (
        <>
            <label>
                <span>{data.personType === 'natural' ? 'Nombre completo' : 'Nombre o Razon Social'}:</span>
                <input type="text" name="name" onChange={handleValuesForm}/>
            </label>
            <label>
                <span>{data.personType === 'natural' ? 'Numero de identidad' : 'Nit'}:</span>
                <input type="number" name="identification" onChange={handleValuesForm}/>
            </label>
            <label>
                <span>Correo electronico:</span>
                <input type="email" name="email" onChange={handleValuesForm}/>
            </label>
            <label>
                <span>Numero de telefono:</span>
                <input type="tel" name="telephone" onChange={handleValuesForm}/>
            </label>
        </>
    )

    const renderReasonRequest = () => (
        <>
            <Form.Label>Motivo de la solicitud:</Form.Label>
            <div  className="mb-3" style={{padding: '10px'}}>
                <Form.Check
                    inline
                    label="Petición"
                    name="reasonRequest"
                    type="radio"
                    onChange={handleValuesForm}
                    value="peticion"
                    checked={data.reasonRequest === 'peticion'}
                />
                <Form.Check
                    inline
                    label="Queja"
                    name="reasonRequest"
                    type="radio"
                    onChange={handleValuesForm}
                    value="queja"
                    checked={data.reasonRequest === 'queja'}
                />
                <Form.Check
                    inline
                    label="Recurso de reposición y en subsidio de apelación ante la SIC"
                    name="reasonRequest"
                    type="radio"
                    onChange={handleValuesForm}
                    value="recurso de reposición y en subsidio de apelación ante la SIC"
                    checked={data.reasonRequest === 'recurso de reposición y en subsidio de apelación ante la SIC'}
                />
            </div>
        </>
    )

    const renderTypeRequirement = () => (
        <>
                <Form.Label>Mi requerimiento es por:</Form.Label>
                <div  className="mb-3" style={{padding: '10px'}}>
                {TypesRequirement.map( (type, index) => (
                    <Form.Check
                        inline
                        label={type.label}
                        name="typeRequirement"
                        type="radio"
                        onChange={handleValuesForm}
                        value={type.value}
                        checked={data.typeRequirement === type.value}
                        key={index}
                    />
                ))}
                </div>
        </>
    )

    const renderTypeRequirementOptions = () => {
        const typesByRequirement = getTypesByRequirementByValue(data.typeRequirement)
        return typesByRequirement.value !== 'otros' && <label>
            <span>Elige el objeto de la petición solicitud o recurso</span>
            <select id="person-type" value={data.tematic} name="tematic" onChange={handleValuesForm}>
                {typesByRequirement.options.map((option, index) => (
                    <option value={option} key={index}>{option}</option>
                ))}
            </select>
        </label>
    }

    return (
        <Layout>
            <div className="page-title-area">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="page-title-item text-center">
                                <h2 className="title">PQR</h2>
                                <nav aria-label="breadcrumb">


                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link  to={"/"}>Inicio </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            PQR
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            {/* page title */}
                        </Col>
                    </Row>
                    {/* row */}
                </Container>
                </div>
                {/* container */}
                <div className="pt-90 pb-120">
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="title" style={{textAlign: 'center'}}>Petición, Queja y Recursos</h1>
                                <br/>
                                <p>
                                    Todas las personas tienen derecho a presentar peticiones respetuosas a las autoridades por motivos de interés general o particular y a obtener pronta solución.
                                    <br/>
                                    Las PQR´s, (Peticiones, Quejas y Recursos), son las actividades básicas del proceso de servicio al cliente desarrolladas en las oficinas comerciales que responden a las necesidades de los clientes.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <br/>
                    <br/>
                    <Container>
                        <Row>
                            <Col md="5">
                                <p>
                                    <strong>Petición:</strong> es una solicitud verbal o escrita que se dirige a una entidad y/o servidor público, con el propósito de requerir su intervención en un asunto puntual y concreto. La petición puede ser una solicitud, una información o una sugerencia.
                                    <br/>
                                    <br/>
                                    <strong>Queja:</strong> es una inconformidad verbal o escrita que presenta un usuario contra la actuación de determinado funcionario u oficina.
                                    <br/>
                                    <br/>
                                    <strong>Recurso:</strong> Petición de revisión de un acto definitivo (Respuesta a una PQR) realizada por la misma administración que lo dictó, a fin de obtener la modificación, revocación o invalidación de dicha decisión.
                                    <br/>
                                    <br/>
                                    <strong>Solicitud de Indemnización:</strong> es una inconformidad verbal o escrita presentada por un usuario por la no prestación o por la deficiencia de un servicio a cargo de la empresa.
                                </p>
                            </Col>
                            <Col>
                                <div className="form-pqr">
                                    <h4>Registre una PQR</h4>
                                    <div className="form-pqr__body">
                                        <form>
                                            <label>
                                                <span>Tipo de persona</span>
                                                <select id="person-type" value={data.personType} name="personType" onChange={handleValuesForm}>
                                                    <option value="natural">Natural</option>
                                                    <option value="juridica">Juridica</option>
                                                </select>
                                            </label>
                                            {renderReasonRequest()}
                                            {renderTypeRequirement()}
                                            {renderTypeRequirementOptions()}
                                            <label>
                                                <span>Hechos en que se fundamenta la petición</span>
                                                <textarea rows="7" required={true} name="note" onChange={handleValuesForm} />
                                            </label>
                                            {renderInfoPerson()}
                                            <label>
                                                <span>Adjunta un documento</span>
                                                <input type="file" style={{paddingLeft: '0'}} ref={refFile}  accept=".pdf" />
                                            </label>
                                            <button
                                              className=" wow fadeInUp"
                                              data-wow-duration="1s"
                                              data-wow-delay=".4s"
                                              type="submit"
                                              onClick={handlePQR}
                                            >
                                              Enviar
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
        </Layout>
    )
}
