import Layout from "../../layouts";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";

export function PaymentPage() {
    return (
        <Layout>
            <div className="page-title-area">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="page-title-item text-center">
                                <h2 className="title">Paga tu factura</h2>
                                <nav aria-label="breadcrumb">


                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link  to={"/"}>Inicio </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Paga tu factura
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="services-item-area pb-50">
                <Container>
                    <Row className="justify-content-center service-list">
                        <Col lg="4" md="6" sm="8" className="card-service">
                            <div className="single-services-item mt-30 pt-60" style={{height: '550px', textAlign: 'center'}}>
                                <img src="assets/images/icomredes/bc-logo.png" alt="" />
                                <p style={{position: 'relative', bottom: '61px'}}>Escanea este codigo QR para pagar tu factura de una manera facil.</p>
                                <img src="assets/images/icomredes/bc-qr-icomredes.jpeg" alt="bc-qr-icomredes" style={{width: '260px', position: 'relative', bottom: '85px'}} />
                                <div className="services-overlay payment-services-overlay">
                                    <i className="fal fa-money-bill" />
                                    <h4 className="title">BANCOLOMBIA</h4>
                                    <p>
                                        CONVENIO DE RECAUDO BANCOLOMBIA <strong>NUMERO 82208</strong>, NOMBRE ICOMREDES y DEBERÁ DAR EL NÚMERO DE CEDULA DEL TITULAR DEL SERVICIO COMO REFERENCIA DE PAGO.  Podrá consignar en los puntos, Corresponsales bancarios: EXITO,  OLIMPICA o Corresponsales Bancarios autorizados con la tablilla amarilla de Bancolombia.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        {/*<Col lg="4" md="6" sm="8" className="card-service">*/}
                        {/*    <div className="single-services-item mt-30" style={{height: '550px'}}>*/}
                        {/*        <img src="https://ccoutletfactory.com/wp-content/uploads/2020/09/via-baloto-outlet-factory-300x207-1.jpg" alt="" style={{paddingTop: '30px'}} />*/}
                        {/*        <div className="services-overlay payment-services-overlay">*/}
                        {/*            <i className="fal fa-money-bill" />*/}
                        {/*            <h4 className="title"> PUNTOS BALOTOS</h4>*/}
                        {/*            <p>*/}
                        {/*                En puntos BALOTOS a la <strong>cuenta de ahorros  8622011650  del BANCO COLPATRIA</strong>, después de hacer su consignación por favor enviar el recibo al siguiente correo electrónico con sus datos. icomredes@gmail.com o por WhatsApp al número 3004693986, para hacer el recibo correspondiente  del pago de su servicio. Gracias por su atención. (Nota: No perder el recibo de pago).*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}
