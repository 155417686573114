import React from "react";
import { FaWhatsappSquare } from "react-icons/all";

//styles
import "./style.scss"

export function FloatingButtons({ label }) {

    return (
        <div className="floating-button">
            <div className="floating-button__content">
                <a href="https://wa.me/573004693986/?text=Hola, puedes ayudarme con un inquietud que tengo?" target="__blank" className="communications-link">
                    <FaWhatsappSquare size="3.5em" className="whatsapp-icon"/>{' '}
                </a>
            </div>
        </div>
    )
}
