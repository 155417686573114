import React from 'react';
import Header from '../../layouts/partials/header/Index';
import Footer from '../../layouts/partials/footer/index';

import Banner from '../../componets/banner/Index';
import WhoWeAreArea from '../../componets/whoWeAreArea/Index';
import SolutionPart from '../../componets/solutionPart/Index';
import WhyChoosePart from '../../componets/whyChoosePart/Index';


class Index1 extends React.Component {

    render() { 
        return ( 
            <React.Fragment>
                <Header />
                <Banner/>
                <WhoWeAreArea/>
                <SolutionPart/>
                {/*<LatestServicesPart/>*/}
                <WhyChoosePart/>
                {/*<TeamMemberPart/>*/}
                {/*<ContactUsPart/>*/}
                {/*<OueChoosePart/>*/}
                {/*<MeetUsPart/>*/}
                {/*<LatestNewsPart/>*/}
                {/*<Brand2Part/>*/}
                <Footer />
            </React.Fragment>
         );
    }
}
 
export default Index1;
