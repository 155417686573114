import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/images/icomredes/Iogo-icomredes-box.jpg'

class AboutIntroPart extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal = event  => {
    this.setState({isOpen: true})
    event.preventDefault()
  }

    render() { 

        return ( 
          <div className="about-intro-area pt-120">
            <Container>
             {/*<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='TdSA7gkVYU0' onClose={() => this.setState({isOpen: false})} />*/}
              <Row>
                <Col lg="6">
                  <div className="intro-thumb">
                    <img src={logo} alt="" />
                    {/*<Link className="video-popup" to="http://bit.ly/2VuPnrx" onClick={this.openModal}>*/}
                    {/*  <i className="fas fa-play" />*/}
                    {/*</Link>*/}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="intri-content">
                    <span>Soluciones Del Futuro</span>
                    <h2 className="title">
                      Nuestra Mision.
                    </h2>
                    <p className="text-2">
                      Ofreciendo servicios en telecomunicaciones, redes electricas,
                      seguridad electronica y sistemas informaticos con altos estandares de calidad y excelencia,
                      a tarvés de la interconexión de resdes, apoyado por un grupo de personal capacitado, calificaso,
                      competitivo, disciplinado, motivado, dispuesto a satifacer las necesidades y requerimientos de
                      nuestris usuarios, ayudando y mejorando cada dia la calidad de nuestros servicios
                    </p>
                    {/*<Link*/}
                    {/*  className="main-btn wow fadeInUp"*/}
                    {/*  to={"/"}*/}
                    {/*  data-wow-duration="1s"*/}
                    {/*  data-wow-delay=".4s"*/}
                    {/*>*/}
                    {/*  Learn More*/}
                    {/*</Link>*/}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
         );
    }
}
 
export default AboutIntroPart;
