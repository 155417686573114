import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class Footer extends React.Component {

  render() { 
      return ( 
        <React.Fragment>
        <footer className="footer-area footer-area-2 footer-area-1 bg_cover" style={{backgroundImage: 'url(assets/images/footer-bg.jpg)'}}>
          <div className="footer-overlay">
            <Container className="position-relative">
              <Row>
                <Col lg="4" md="7">
                  <div>
                    <img src="assets/images/footer-icomredes.png" alt=""/>
                  </div>
                  <div className="widget-item-1">
                    <img src="/assets/images/logo-1.1.png" alt="" />
                    <p style={{fontSize: '20px'}}>
                      Nuestro principal objetivo es brindar soluciones integrales a las necesidades de los usuarios, llevando servicios de excelente calidad sin importar la distancia, el lugar o estrato social
                    </p>
                  </div> {/* widget item 1 */}
                </Col>
                <Col lg="3" md="5" className="offset-lg-1">
                </Col>
                <Col lg="4" md="6">
                  <div className="widget-item-2 widget-item-3 mt-30">
                    <h4 className="title">Horario de trabajo</h4>
                    <ul>
                      <li>Lunes - Viernes y sabados: <br/> 8:00 AM - 12:00 M & 2:00 PM - 6:00 PM</li>
                      <br/>
                      <li>Telefono: (+57) 300 469 3986 </li>
                      <br/>
                      <li>Direccion Oficina: Barrios los cerezos Mz E1 lote 22</li>
                    </ul>
                    {/*<p><span>For more then 30 years,</span> IT Service has been a reliable partner in the field of logistics and cargo forwarding.</p>*/}
                    {/*<Link  to={"#be"}><i className="fal fa-angle-right" />Discover More</Link>*/}
                  </div> {/* widget item 3 */}
                </Col>
              </Row> {/* row */}
              <Row>
                <Col lg="12">
                  <div className="footer-copyright">
                    <p>Copyright By@ <span>Icomredes</span> - {(new Date().getFullYear())}</p>
                  </div> {/* footer copyright */}
                </Col>
              </Row> {/* row */}
            </Container> {/* container */}
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <div className="back-to-top back-to-top-2">
          <a  href="#backTotop">
            <i className="fas fa-arrow-up" />
          </a>
        </div>
        {/*====== BACK TO TOP ======*/}

      </React.Fragment>
    );
  }
}
 
export default Footer;
