import React from 'react';
import PartialNav from './PartialNav';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Logo1 from '../../../assets/images/icomredes/logo-final-icomredes.png';
import { Row, Col , Navbar } from 'react-bootstrap';
import { SiWhatsapp, SiGmail, SiInstagram } from "react-icons/si";
import './index.scss'
import {IcomButton} from "../../../componets/element-design/button";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      isTop: true,
      menuShowHideToggle: false,
      menuShowHideToggleClass: ' collapse'
    };
  }

  componentDidMount() {

    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    });
  }

  render() {
        return (
            <React.Fragment>
          <header className="header-area header-area-2 header-area-4">
            <div className="header-top pl-30 pr-30 white-bg">
              <Row className="align-items-center">
                <Col md="6" sm="7">
                  <div className="header-left-side text-center text-sm-left">
                    <ul>
                      <li>
                        <a  href="mailto:icomredes@gmail.com" target="__blank">
                          <SiGmail size="1.4em" className="gmail-icon"/>{' '}
                          icomredes@gmail.com
                        </a>
                      </li>
                      <li>
                        <a href="https://wa.me/573004693986/?text=Hola, puedes ayudarme con un inquietud que tengo?" target="__blank" className="communications-link">
                          <SiWhatsapp size="1.4em" className="whatsapp-icon"/>{' '}
                          (+57) 300 469 3986
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/icomredes/" target="__blank">
                          <SiInstagram size="1.4em" className="instagram-icon"/>{' '}
                          @icomredes
                        </a>
                      </li>
                      </ul>
                  </div>
                </Col>
                <Col md="6" sm="5">
                  <div className="header-right-social text-center text-sm-right">
                    <a href="https://clientes.wisphub.net/accounts/login/?next=/panel/" target="__blank">
                      <IcomButton label="Consultar Factura"/>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="header-nav" >
              <div 
                className={this.state.isTop ? 'navigation' : 'bg-white navigation sticky'}
              >
                <Navbar bg="light" expand="lg">
                  <Navbar.Brand as={Link} to={'/'}>
                    <img className="logo-nav" src={Logo1} alt="" />
                  </Navbar.Brand>

                  <Navbar.Toggle  onClick={this.menuShowHide }   className={`navbar-toggler 
                    ${this.state.isTop ? ' ' : ' sticky'}
                    ${this.state.menuShowHideToggle ? 'active' : 'collapsed'}
                  `} 
                    aria-controls="navbarSupportedContent"
                    aria-label="Toggle navigation">
                    <span className="toggler-icon" />
                    <span className="toggler-icon" />
                    <span className="toggler-icon" />
                  </Navbar.Toggle> 

                  <Navbar.Collapse className="sub-menu-bar"  id="navbarSupportedContent">
                    <PartialNav />
                  </Navbar.Collapse> 
                  {/* navbar collapse */}
                  <div className="bar-area d-none d-xl-block">
                    <ul>
                      <li className="d-none d-md-block d-lg-none">
                        <Link to={"/"}>
                          <FontAwesomeIcon icon={faBars}   className="mr-1"/>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Navbar>
              </div> {/* navigation */}
            </div>
          </header>
              </React.Fragment>
         );
    }
}
 
export default Header;
