import React from 'react';
import './Index.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ImageAbout1 from '../../assets/images/icomredes/about-1.jpeg'
import ImageAbout2 from '../../assets/images/icomredes/about-4.jpeg'
import ImageAbout3 from '../../assets/images/icomredes/about-3.jpeg'

class Index extends React.Component {

    render() { 
        return ( 
          <div className="about-experience-area pb-120">
            <Container>
              <Row>
                <Col lg="5">
                  <div className="experience-item">
                    <span>Nuestro Objetivo</span>
                    <h2 className="title">soluciones integrales</h2>
                  </div>
                  {/* experience item */}
                </Col>
                <Col lg="6" className="offset-lg-1">
                  <div className="experience-item">
                    <p>
                      Nuestro principal objetivo es brindar soluciones integrales a las necesidades de
                      los usuarios, llevando servicios de excelente calidad
                      sin importar la distancia, el lugar o estrato social.
                    </p>
                    {/*<Link to={"/about"}>Read More</Link>*/}
                  </div>
                  {/* experience item */}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration=".5s"
                  data-wow-delay=".2s"
                >
                  <div className="single-experience mt-30">
                    <img src={ImageAbout1} alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">Soporte Continuo</h5>
                      <p>
                       Lo primordial es brindarle soluciones
                       sencillas y prácticas.
                      </p>
                      {/*<Link to={"about"}>Read More</Link>*/}
                      {/*<i className="fal fa-laptop-code" />*/}
                    </div>
                  </div>
                  {/* single experience */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  <div className="single-experience mt-30">
                    <img src={ImageAbout2} alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">Calidad Y Excelencia</h5>
                      <p>
                        Para Crear experiencias especiales con nuestros clientes
                      </p>
                      {/*<Link to="/home-1">Read More</Link>*/}
                      {/*<i className="fal fa-headphones-alt" />*/}
                    </div>
                  </div>
                  {/* single experience */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".6s"
                >
                  <div className="single-experience mt-30">
                    <img src={ImageAbout3} alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">Soporte Técnico</h5>
                      <p>
                        Brindar atención específica enfocada a la solución de problemas.
                      </p>
                      {/*<Link to={"/about"}>Read More</Link>*/}
                      {/*<i className="fal fa-analytics" />*/}
                    </div>
                  </div>
                  {/* single experience */}
                </div>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default Index;
