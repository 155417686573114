import React, {useEffect, useState} from 'react';
import { Container, Row } from 'react-bootstrap';
import sanityClient from "@sanity/client";
import './Index.scss'

function WhoWeAreArea(){

      const [fibraServices, setFibraServices] = useState([])

      useEffect(() => {

        const clientSanity = sanityClient({
          projectId: 'qepg5qgt',
          dataset: 'production',
          token: 'sk76atFvpYxjQnBkaRIKowotNKfGqbDYlkNQqSe2R0dkX6TbMoKk1aCgKFUTtZJdug6LcS7d5RCOVKN2lrLMcaZMcLtwAspgpZu3qAK9V7IkkTk8UOZCW2gHnm6UuRC0u3oEUrLqwQbQzy560oggKrIYVg7hHuxyD3wO2A0JSulcezZfikWs', // or leave blank for unauthenticated usage
          useCdn: true, // `false` if you want to ensure fresh data
        })

        const query = '*[_type == "product"] | order(order) {title, description, "image":image.asset->url,"serviceName": service->title, features}'
        const params = {minSeats: 2}

        clientSanity.fetch(query, params).then((services) => {
          services = services.filter(service => service.serviceName === 'Fibra Optica')
          setFibraServices(services)
        })

      },[])

        return (
          <div className="who-we-are-area pt-110 pb-120">
            <Container>
              <Row>
                <div
                  className="col-lg-5 wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  <div className="section-title">
                    <span>Nuestro Objetivo</span>
                    <h2 className="title">
                      Trabajamos duro y con amor para ti y tu familia
                    </h2>
                  </div>
                  {/* section title */}
                </div>
                <div
                  className="col-lg-6 offset-lg-1 wow fadeInRight"
                  data-wow-duration="1.5s"
                  data-wow-delay="0s"
                >
                  <div className="section-title">
                    <p>
                      Brindar soluciones integrales a las necesidades de
                      los usuarios, llevando servicios de excelente calidad
                      sin importar la distancia, el lugar o estrato social.
                    </p>
                  </div>
                  {/* section title */}
                </div>
              </Row>
              <Row>
                {fibraServices.map((product, index) => (
                    <div
                        className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"
                        data-wow-duration=".5s"
                        data-wow-delay="0s"
                        key={index}
                    >
                      <div className="what-we-do-item text-center mt-30">
                        {/*<FontAwesomeIcon  icon={faLaptopCode} />*/}
                        <img className="product-home-view" src={product.image} alt=""/>
                        <br/>
                        <h5 className="title">{product.title}</h5>
                        <p>{product.description.length < 40 ? product.description : product.description.substring(0, 40) +'...' }</p>
                        <h4>{product.serviceName}</h4>
                        {/*<Link to={"case-details"}>*/}
                        {/*  <FontAwesomeIcon icon={faLongArrowAltRight}/>*/}
                        {/*</Link>*/}
                      </div>
                      {/* what we do item */}
                    </div>
                ))}
                {/*<div*/}
                {/*  className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"*/}
                {/*  data-wow-duration=".5s"*/}
                {/*  data-wow-delay="0s"*/}
                {/*>*/}
                {/*  <div className="what-we-do-item text-center mt-30">*/}
                {/*    <FontAwesomeIcon  icon={faLaptopCode} />*/}
                {/*    <h5 className="title">Servicio 1</h5>*/}
                {/*    <p>Resumen Servicio 1, resumen del serivicio aqui</p>*/}
                {/*    <Link to={"case-details"}>*/}
                {/*      <FontAwesomeIcon icon={faLongArrowAltRight}/>*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*  /!* what we do item *!/*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"*/}
                {/*  data-wow-duration="1s"*/}
                {/*  data-wow-delay="0s"*/}
                {/*>*/}
                {/*  <div className="what-we-do-item text-center mt-30">*/}
                {/*    <FontAwesomeIcon icon={faFingerprint}  />*/}
                {/*    <h5 className="title">Servicio 2</h5>*/}
                {/*    <p>Resumen Servicio 2, resumen del serivicio aqui</p>*/}
                {/*    <Link to={"case-details"}>*/}
                {/*      <FontAwesomeIcon icon={faLongArrowAltRight}/>*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*  /!* what we do item *!/*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"*/}
                {/*  data-wow-duration="1.5s"*/}
                {/*  data-wow-delay="0s"*/}
                {/*>*/}
                {/*  <div className="what-we-do-item text-center mt-30">*/}
                {/*    <FontAwesomeIcon icon={faChalkboard} />*/}
                {/*    <h5 className="title">Servicio 3</h5>*/}
                {/*    <p>Resumen Servicio 3, resumen del serivicio aqui</p>*/}
                {/*    <Link to={"case-details"}>*/}
                {/*      <FontAwesomeIcon icon={faLongArrowAltRight}/>*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*  /!* what we do item *!/*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className="col-lg-3 col-md-6 col-sm-6 wow slideInUp"*/}
                {/*  data-wow-duration="2s"*/}
                {/*  data-wow-delay="0s"*/}
                {/*>*/}
                {/*  <div className="what-we-do-item text-center mt-30">*/}
                {/*    <FontAwesomeIcon icon={faDatabase} />*/}
                {/*    <h5 className="title">Servicio 4</h5>*/}
                {/*    <p>Resumen Servicio 4, resumen del serivicio aqui</p>*/}
                {/*    <Link to={"case-details"}>*/}
                {/*      <FontAwesomeIcon icon={faLongArrowAltRight}/>*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*  /!* what we do item *!/*/}
                {/*</div>*/}
              </Row>
              {/* row */}
            </Container>
            {/* container */}
            <div className="what-we-are-shape-1">
              <img src="/assets/images/what-we-are-shape-1.png" alt="shape" />
            </div>
            <div className="what-we-are-shape-2">
              <img src="/assets/images/what-we-are-shape-2.png" alt="shape" />
            </div>
          </div>
         );
}
 
export default WhoWeAreArea;
