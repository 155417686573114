import React from 'react';
import Layout from "../../layouts";
import{ Container, Row, Col} from "react-bootstrap";
import './style.scss'

export default function ReadLey(){
    return (
        <Layout>
            <div className="page-title-area">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="page-title-item text-center">
                                <h2 className="title">Ley 679 De 2001</h2>
                                <p className="text-1">(agosto 3)</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="container pb-120 pt-120">
                <p className="text-center">
                    <span className="pt-3">Diario Oficial No. 44.509 de 4 de agosto de 2001</span>
                    <span className="pt-3">Por medio de la cual se expide un estatuto para prevenir y contrarrestar la explotación, la pornografía y el turismo sexual con menores, en desarrollo del artículo 44 de la Constitución.</span>
                    <span className="pt-3">
                        Resumen de Notas de Vigencia<br/>
                        EL CONGRESO DE LA REPÚBLICA
                    </span>
                </p>
                <p>DECRETA:</p>
                <p>ARTÍCULO 1o. OBJETO. Esta ley tiene por objeto dictar medidas de protección contra la explotación, la
                    pornografía, el turismo sexual y demás formas de abuso sexual con menores de edad, mediante el
                    establecimiento de normas de carácter preventivo y sancionatorio, y la expedición de otras
                    disposiciones en desarrollo del artículo 44 de la Constitución.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 2o. DEFINICIÓN. Para los efectos de la presente ley, se entiende por menor de edad la
                    persona que no ha cumplido los dieciocho años.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 3o. AMBITO DE APLICACIÓN. A la presente ley se sujetarán las personas naturales y jurídicas
                    de nacionalidad colombiana, o extranjeras con domicilio en el país, cuya actividad u objeto social
                    tenga relación directa o indirecta con la comercialización de bienes y servicios a través de redes
                    globales de información, los prestadores de servicios turísticos a los que se refiere el artículo 62
                    de la Ley 300 de 1996 y las demás personas naturales o jurídicas de nacionalidad colombiana, o
                    extranjeras con domicilio en el país, que puedan generar o promover turismo nacional o
                    internacional.</p>
                <p>Se sujetarán igualmente a la presente ley las personas naturales que, teniendo su domicilio en el
                    exterior, realicen por sí mismas o en representación de una sociedad las actividades a las que hace
                    referencia el inciso primero del presente artículo, siempre que ingresen a territorio
                    colombiano.</p>
                <p>Del mismo modo, en virtud de la cooperación internacional prevista en el artículo 13, el Gobierno
                    Nacional incorporará a los tratados y convenios internacionales que celebre con otros países el
                    contenido de la presente ley, a fin de que su aplicación pueda extenderse a personas naturales o
                    jurídicas extranjeras, domiciliadas en el exterior, cuyo objeto social sea el mismo al que se
                    refiere el inciso primero del presente artículo.</p>
                <p>CAPITULO II.</p>
                <p>DEL USO DE REDES GLOBALES DE INFORMACIÓN EN RELACIÓN CON MENORES.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 4o. COMISIÓN DE EXPERTOS. Dentro del mes siguiente a la vigencia de la presente ley, el
                    Instituto Colombiano de Bienestar Familiar conformará una Comisión integrada por peritos jurídicos y
                    técnicos, y expertos en redes globales de información y telecomunicaciones, con el propósito de
                    elaborar un catálogo de actos abusivos en el uso y aprovechamiento de tales redes en lo relacionado
                    con menores de edad. La Comisión propondrá iniciativas técnicas como sistemas de detección, filtro,
                    clasificación, eliminación y bloqueo de contenidos perjudiciales para menores de edad en las redes
                    globales, que serán transmitidas al Gobierno nacional con el propósito de dictar medidas en
                    desarrollo de esta ley.</p>
                <p>Los miembros de la Comisión serán funcionarios de la planta de personal ya existente en las entidades
                    públicas cuya función sea la protección del menor y el área de comunicaciones, y su designación
                    corresponderá al representante legal de las mismas. En todo caso, formarán parte de la Comisión, el
                    Director del Instituto Colombiano de Bienestar Familiar, el Defensor del Pueblo, un experto en
                    delitos informáticos del DAS, el Fiscal General de la Nación, y a sus reuniones será invitado el
                    delegado para Colombia de la Unicef.</p>
                <p>La Comisión a la que se refiere el presente artículo, presentará un informe escrito al Gobierno
                    Nacional dentro de los cuatro meses siguientes a su conformación, en el cual consten las
                    conclusiones de su estudio, así como las recomendaciones propuestas.</p>
                <p>PARÁGRAFO. La Comisión de Expertos a la que hace referencia el presente artículo dejará de funcionar
                    de manera permanente, una vez rendido el informe para la cual será conformada. No obstante, el
                    Gobierno Nacional podrá convocarla siempre que lo estime necesario para el cabal cumplimiento de los
                    fines previstos en la presente ley.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 5o. INFORME DE LA COMISIÓN. Con base en el informe de que trata el artículo anterior, el
                    Gobierno nacional, con el apoyo de la Comisión de Regulación de Telecomunicaciones, adoptará las
                    medidas administrativas y técnicas destinadas a prevenir el acceso de menores de edad a cualquier
                    modalidad de información pornográfica, y a impedir el aprovechamiento de redes globales de
                    información con fines de explotación sexual infantil u ofrecimiento de servicios comerciales que
                    impliquen abuso sexual con menores de edad.</p>
                <p>Las regulaciones sobre medidas administrativas y técnicas serán expedidas por el Gobierno Nacional
                    dentro de los seis (6) meses siguientes a la fecha de vigencia de la presente ley.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 6o. SISTEMAS DE AUTORREGULACIÓN. El Gobierno nacional, por intermedio del Ministerio de
                    Comunicaciones, promoverá e incentivará la adopción de sistemas de autorregulación y códigos de
                    conducta eficaces en el manejo y aprovechamiento de redes globales de información. Estos sistemas y
                    códigos se elaborarán con la participación de organismos representativos de los proveedores y
                    usuarios de servicios de redes globales de información.</p>
                <p>Para estos efectos, el Ministerio de Comunicaciones convocará a los sujetos a los que hace referencia
                    el artículo tercero de la presente ley, para que formulen por escrito sus propuestas de
                    autorregulación y códigos de conducta.</p>
                <p>Los códigos de conducta serán acordados dentro del año siguiente a la vigencia de la presente ley y
                    se remitirá copia a las Secretarías Generales del Senado y de la Cámara.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 7o. PROHIBICIONES. Los proveedores o servidores, administradores y usuarios de redes
                    globales de información no podrán:</p>
                <p>1. Alojar en su propio sitio imágenes, textos, documentos o archivos audiovisuales que impliquen
                    directa o indirectamente actividades sexuales con menores de edad.</p>
                <p>2. Alojar en su propio sitio material pornográfico, en especial en modo de imágenes o videos, cuando
                    existan indicios de que las personas fotografiadas o filmadas son menores de edad.</p>
                <p>3. Alojar en su propio sitio vínculos o links, sobre sitios telemáticos que contengan o distribuyan
                    material pornográfico relativo a menores de edad.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 8o. DEBERES. Sin perjuicio de la obligación de denuncia consagrada en la ley para todos los
                    residentes en Colombia, los proveedores, administradores y usuarios de redes globales de información
                    deberán:</p>
                <p>1. Denunciar ante las autoridades competentes cualquier acto criminal contra menores de edad de que
                    tengan conocimiento, incluso de la difusión de material pornográfico asociado a menores.</p>
                <p>2. Combatir con todos los medios técnicos a su alcance la difusión de material pornográfico con
                    menores de edad.</p>
                <p>3. Abstenerse de usar las redes globales de información para divulgación de material ilegal con
                    menores de edad.</p>
                <p>4. Establecer mecanismos técnicos de bloqueo por medio de los cuales los usuarios se puedan proteger
                    a sí mismos o a sus hijos de material ilegal, ofensivo o indeseable en relación con menores de
                    edad.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 9o. PUNTOS DE INFORMACIÓN. El Ministerio de Comunicaciones creará dentro del mes siguiente
                    a la expedición de la presente ley, una línea telefónica directa que servirá como punto de
                    información para proveedores y usuarios de redes globales de información acerca de las implicaciones
                    legales de su uso en relación con esta ley.</p>
                <p>Así mismo, dentro del término arriba señalado, creará una página electrónica en las redes globales, a
                    la cual puedan remitirse los usuarios para formular denuncias contra eventos de pornografía con
                    menores de edad y para señalar las páginas electrónicas en las que se ofrezcan servicios sexuales
                    con menores de edad o de pornografía con menores de edad, así como señalar a los autores o
                    responsables de tales páginas.</p>
                <p>En caso de que el Ministerio de Comunicaciones reciba por vía telefónica o electrónica denuncias que
                    puedan revestir un carácter penal, las mismas deberán ser remitidas de inmediato a las autoridades
                    competentes, con el fin de que adelanten la investigación que corresponda.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 10. SANCIONES ADMINISTRATIVAS. El Ministerio de Comunicaciones tomará medidas a partir de
                    las denuncias formuladas, y sancionará a los proveedores o servidores, administradores y usuarios
                    responsables que operen desde territorio colombiano, sucesivamente de la siguiente manera:</p>
                <p>1. Multas hasta de 100 salarios mínimos legales vigentes.</p>
                <p>2. Cancelación o suspensión de la correspondiente página electrónica.</p>
                <p>&nbsp;</p>
                <p>Notas de Vigencia<br/>
                    Legislación Anterior<br/>
                    PARÁGRAFO. &lt;Parágrafo adicionado por el artículo 3 de la Ley 1336 de 2009. El nuevo texto es el
                    siguiente:&gt; El Ministerio de Comunicaciones tendrá competencia para exigir, en el plazo que este
                    determine, toda la información que considere necesaria a los proveedores de servicios de internet,
                    relacionada con la aplicación de la Ley 679 y demás que la adicionen o modifiquen. En particular
                    podrá:</p>
                <p>1. Requerir a los proveedores de servicios de internet a fin de que informen en el plazo y forma que
                    se les indique, qué mecanismos o filtros de control están utilizando para el bloqueo de páginas con
                    contenido de pornografía con menores de edad en Internet.</p>
                <p>2. Ordenar a los proveedores de servicios de internet incorporar cláusulas obligatorias en los
                    contratos de portales de internet relativas a la prohibición y bloqueo consiguiente de páginas con
                    contenido de pornografía con menores de edad.</p>
                <p>Los proveedores de servicios de internet otorgarán acceso a sus redes a las autoridades judiciales y
                    de policía cuando se adelante el seguimiento a un número IP desde el cual se produzcan violaciones a
                    la presente ley.</p>
                <p>La violación de estas disposiciones acarreará la aplicación de las sanciones administrativas de que
                    trata el artículo 10 de la Ley 679 de 2001, con los criterios y formalidades allí previstas.</p>
                <p>Notas de Vigencia<br/>
                    Concordancias<br/>
                    CAPITULO III.</p>
                <p>PERSONERÍA PROCESAL Y ACCIONES DE SENSIBILIZACIÓN.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 11. PERSONERÍA PROCESAL. Toda persona natural o jurídica tendrá la obligación de denunciar
                    ante las autoridades competentes cualquier hecho violatorio de las disposiciones de la presente ley.
                    Las asociaciones de padres de familia y demás organizaciones no gubernamentales cuyo objeto sea la
                    protección de la niñez y de los derechos de los menores de edad, tendrán personería procesal para
                    denunciar y actuar como parte en los procedimientos administrativos y judiciales encaminados a la
                    represión del abuso sexual de menores de edad.</p>
                <p>La Defensoría del Pueblo y las personerías municipales brindarán toda la asesoría jurídica que las
                    asociaciones de padres de familia requieran para ejercer los derechos procesales a que se refiere
                    este artículo. La omisión en el cumplimiento de esta obligación constituye falta disciplinaria
                    gravísima.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 12. MEDIDAS DE SENSIBILIZACIÓN. Las autoridades de los distintos niveles territoriales y el
                    Instituto Colombiano de Bienestar Familiar, implementarán acciones de sensibilización pública sobre
                    el problema de la prostitución, la pornografía y el abuso sexual de menores de edad. El Gobierno
                    Nacional, por intermedio del Ministerio de Educación, supervisará las medidas que a este respecto
                    sean dictadas por las autoridades departamentales, distritales y municipales.</p>
                <p>PARÁGRAFO 1o. Por medidas de sensibilización pública se entiende todo programa, campaña o plan
                    tendiente a informar por cualquier medio sobre el problema de la prostitución, la pornografía con
                    menores de edad y el abuso sexual de menores de edad; sobre sus causas y efectos físicos y
                    psicológicos y sobre la responsabilidad del Estado y de la sociedad en su prevención.</p>
                <p>PARÁGRAFO 2o. La Procuraduría General de la Nación, a través de la Delegada para la Defensa de la
                    Familia y el Menor y de los Procuradores Judiciales harán el seguimiento y el control
                    respectivo.</p>
                <p>Concordancias<br/>
                    CAPITULO IV.</p>
                <p>MEDIDAS DE ALCANCE INTERNACIONAL.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 13. ACCIONES DE COOPERACIÓN INTERNACIONAL. El Gobierno Nacional tomará las medidas
                    necesarias para defender los derechos fundamentales de los niños y aumentar la eficacia de las
                    normas de la presente ley, mediante acciones de cooperación internacional acordes con el carácter
                    mundial del problema de la explotación sexual, la pornografía y el turismo asociado a prácticas
                    sexuales con menores de edad. En ese sentido, el Presidente de la República podrá adoptar las
                    siguientes medidas:</p>
                <p>1. Sugerirá la inclusión de normas para prevenir y contrarrestar el abuso sexual de menores de edad
                    en los Convenios de Cooperación Turística que se celebren con otros países.</p>
                <p>2. Tomará la iniciativa para la adopción de acuerdos internacionales que permitan el intercambio de
                    información sobre personas o empresas que ofrezcan servicios relacionados con la explotación sexual
                    de menores de edad, la pornografía con menores de edad y el turismo asociado a prácticas sexuales
                    con menores, mediante la utilización de redes globales de información o de cualquier otro medio de
                    comunicación.</p>
                <p>3. Alentará la realización de acuerdos de asistencia mutua y cooperación judicial en materia de
                    pruebas sobre crímenes asociados a la explotación sexual, la pornografía con menores de edad y el
                    turismo asociado a prácticas sexuales con menores.</p>
                <p>4. Propiciará encuentros mundiales de la Unicef en Colombia con el fin de tratar el problema del
                    abuso sexual con menores de edad.</p>
                <p>5. Alentará el intercambio de información, estadísticas y la unificación de la legislación mundial
                    contra la explotación sexual de menores de edad.</p>
                <p>6. Ofrecerá o concederá la extradición de ciudadanos extranjeros que estén sindicados de conductas
                    asociadas a la explotación sexual y la pornografía con menores de edad y el turismo asociado a
                    prácticas sexuales con menores. Para tales efectos no será necesaria la existencia de un tratado
                    público, ni se exigirá que el hecho que la motiva esté reprimido con una determinada sanción mínima
                    privativa de la libertad, aunque en lo demás la extradición deberá instrumentarse de conformidad con
                    el Código de Procedimiento Penal.</p>
                <p>7. Tomará medidas concretas e inmediatas tendientes a la repatriación de menores de edad que hayan
                    salido ilegalmente del país o con fines de explotación sexual.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 14. DENEGACIÓN Y CANCELACIÓN DE VISAS. No podrá otorgarse visa de ninguna clase para
                    ingresar a territorio colombiano a extranjeros contra los cuales se hubieren iniciado en cualquier
                    Estado investigaciones preliminares, proceso penal o de policía, o se hubieren impuesto multas, o
                    dictado medida de aseguramiento, o se hubiere dictado sentencia condenatoria ejecutoriada por
                    delitos de explotación sexual o contra la libertad, el pudor y la formación sexuales de menores de
                    edad.</p>
                <p>Así mismo, en cualquier momento se les cancelará la visa ya otorgada, sin perjuicio de la
                    correspondiente acción penal que de oficio debe adelantar el Estado colombiano para asegurar la
                    condigna sanción de tales hechos punibles.</p>
                <p>Por las mismas razones procederá la deportación, la expulsión y la inadmisión a territorio
                    colombiano.</p>
                <p>Estas medidas serán adoptadas también en relación con quienes hayan sido sindicados de promover,
                    facilitar u ocultar tales delitos, en cualquier Estado.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 15. SISTEMA DE INFORMACIÓN SOBRE DELITOS SEXUALES CONTRA MENORES. Para la prevención de los
                    delitos sexuales contra menores de edad y el necesario control sobre quienes los cometen, promuevan
                    o facilitan, el Ministerio de Justicia y del Derecho, el Departamento Administrativo de Seguridad,
                    DAS, el Instituto Colombiano de Bienestar Familiar y la Fiscalía General de la Nación desarrollarán
                    un sistema de información en el cual se disponga de una completa base de datos sobre delitos contra
                    la libertad, el pudor y la formación sexuales cometidos sobre menores de edad, sus autores,
                    cómplices, proxenetas, tanto de condenados como de sindicados.</p>
                <p>El Departamento Administrativo de Seguridad y la Fiscalía General de la Nación promoverán la
                    formación de un servicio internacional de información sobre personas sindicad as o condenadas por
                    delitos contra la libertad, el pudor y la formación sexuales sobre menores de edad. Para tal efecto
                    se buscará el concurso de los organismos de policía internacional.</p>
                <p>Concordancias<br/>
                    CAPITULO V.</p>
                <p>MEDIDAS PARA PREVENIR Y CONTRARRESTAR EL TURISMO SEXUAL.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 16. PROGRAMAS DE PROMOCIÓN TURÍSTICA. Los prestadores de servicios turísticos enlistados en
                    el artículo 62 de la Ley 300 de 1996, y las demás personas naturales o jurídicas que puedan generar
                    turismo nacional o internacional, se abstendrán de ofrecer en los programas de promoción turística,
                    expresa o subrepticiamente, planes de explotación sexual de menores. Asimismo, adoptarán medidas
                    para impedir que sus trabajadores, dependientes o intermediarios, ofrezcan orientación turística o
                    contactos sexuales con menores de edad.</p>
                <p>PARÁGRAFO. El Ministerio de Desarrollo Económico exigirá a los prestadores de servicios turísticos
                    que se acojan a compromisos o códigos de conducta, con el fin de proteger a los menores de edad de
                    toda forma de explotación y violencia sexual originada por turistas nacionales o extranjeros.</p>
                <p>Los Códigos o compromisos de conducta serán radicados en el Ministerio de Desarrollo Económico en un
                    término máximo de seis (6) meses contados a partir de la vigencia de la presente ley, y se les dará
                    amplia divulgación.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 17. DEBER DE ADVERTENCIA. Los establecimientos hoteleros o de hospedaje incluirán una
                    cláusula en los contratos de hospedaje que celebren a partir de la vigencia de la presente ley,
                    informando sobre las consecuencias legales de la explotación y el abuso sexual de menores de edad en
                    el país.</p>
                <p>Las agencias de viaje y de turismo incluirán en su publicidad turística información en el mismo
                    sentido.</p>
                <p>Las aerolíneas nacionales o extranjeras informarán a sus usuarios en viajes internacionales con
                    destino Colombia acerca de la existencia de la legislación contra la explotación sexual de menores
                    de edad.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 18. INSPECCIÓN Y VIGILANCIA. El Ministerio de Desarrollo inspeccionará y controlará las
                    actividades de promoción turística con el propósito de prevenir y contrarrestar la prostitución y el
                    abuso sexual de menores de edad en el sector y sancionará a los prestadores de servicios turísticos
                    involucrados.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 19. INFRACCIONES. Además de las infracciones previstas en el artículo 71 de la Ley 300 de
                    1996, los prestadores de servicios turísticos podrán ser objeto de sanciones administrativas, sin
                    perjuicio de las penales, cuando incurran en alguna de las siguientes conductas:</p>
                <p>1. Utilizar publicidad que sugiera expresa o subrepticiamente la prestación de servicios turísticos
                    sexuales con menores de edad.</p>
                <p>2. Dar información a los turistas, directamente o por intermedio de sus empleados, acerca de lugares
                    desde donde se coordinen o donde se presten servicios sexuales con menores de edad.</p>
                <p>3. Conducir a los turistas a establecimientos o lugares donde se practique la prostitución de menores
                    de edad.</p>
                <p>4. Conducir a los menores de edad, directamente o por intermedio de sus empleados, a los sitios donde
                    se encuentran hospedados los turistas, incluso si se trata de lugares localizados en altamar, con
                    fines de prostitución de menores de edad.</p>
                <p>5. Arrendar o utilizar vehículos en rutas turísticas con fines de prostitución o de abuso sexual con
                    menores de edad.</p>
                <p>6. Permitir el ingreso de menores a los hoteles o lugares de alojamiento y hospedaje, bares, negocios
                    similares y demás establecimientos turísticos con fines de prostitución o de abuso sexual de menores
                    de edad.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 20. SANCIONES. El Ministerio de Desarrollo Económico impondrá las siguientes sanciones, de
                    acuerdo con el procedimiento establecido para tal fin en la Ley 300 de 1996:</p>
                <p>1. Multas hasta por trescientos (300) salarios mínimos legales mensuales vigentes, que se destinarán
                    al Fondo de Promoción Turística para los fines de la presente ley.</p>
                <p>2. Suspensión hasta por noventa (90) días calendario de la inscripción en el Registro Nacional de
                    Turismo.</p>
                <p>3. Cancelación de la inscripción en el Registro Nacional de Turismo que implicará la prohibición de
                    ejercer la actividad turística durante cinco (5) años a partir de la sanción.</p>
                <p>El Ministerio de Desarrollo Económico podrá delegar esta función de vigilancia y control en las
                    entidades territoriales. Esta delegación, sin embargo, no excluye la responsabilidad del delegante
                    por las acciones u omisiones de los delegatarios.</p>
                <p>PARÁGRAFO. Las personas naturales o jurídicas que hubieren sido sancionadas por violación a lo
                    dispuesto en la presente ley, no podrán ser beneficiarias del Certificado de Desarrollo Turístico
                    contemplado en el artículo 48 de la Ley 383 de 1997 y el Decreto 1053 de 1998.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 21. FONDO DE PROMOCIÓN TURÍSTICA. &lt;Artículo derogado por el artículo 20 de la Ley 1101
                    de 2006&gt;</p>
                <p>Notas de Vigencia<br/>
                    Concordancias<br/>
                    Legislación Anterior<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 22. IMPUESTO A VIDEOS PARA ADULTOS. Los establecimientos de comercio, cuando alquilen
                    películas de video de clasificación X para adultos, pagarán un impuesto correspondiente al cinco por
                    ciento (5%) sobre el valor de cada video rentado, con destino a la financiación de los planes y
                    programas de prevención y lucha contra la explotación sexual y la pornografía con menores de edad.
                </p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 23. IMPUESTO DE SALIDA. &lt;Artículo modificado por el artículo 108 de la Ley 1943 de 2018.
                    El nuevo texto es el siguiente:&gt; Los nacionales y extranjeros, residentes o no en Colombia, que
                    salgan del país por vía aérea, cancelarán el valor correspondiente a un dólar de los Estados Unidos
                    de América, o su equivalente en pesos colombianos, al momento de la compra del tiquete aéreo. Dicho
                    recaudo estará a cargo del ICBF, y su destino será la financiación de los planes y programas de
                    prevención y lucha contra la explotación sexual y la pornografía con menores de edad.</p>
                <p>El Gobierno nacional reglamentará la materia.</p>
                <p>Notas de Vigencia<br/>
                    Concordancias<br/>
                    Legislación Anterior<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 24. FONDO CONTRA LA EXPLOTACIÓN SEXUAL DE MENORES. Créase la cuenta especial denominada
                    Fondo contra la explotación sexual de menores, adscrita al Instituto Colombiano de Bienestar
                    Familiar.</p>
                <p>El objetivo principal del Fondo cuenta es proveer rentas destinadas a inversión social con el fin de
                    garantizar la financiación de los planes y programas de prevención y lucha contra la explotación
                    sexual y la pornografía con menores de edad y, más precisamente, con destino a los siguientes fines:
                    construcción de hogares o albergues infantiles, programas de ayuda, orientación, rehabilitación y
                    recuperación física y psicológica de menores de edad que han sido objeto de explotación sexual;
                    financiación de programas de repatriación de colombianos que han sido objeto de explotación sexual,
                    y financiación de mecanismos de difusión para la prevención de acciones delictivas en materia de
                    tráfico de mujeres y niños.</p>
                <p>Las fuentes específicas de los recursos destinados al fondo cuenta, serán las siguientes:</p>
                <p>1. Las partidas que se le asignen en el presupuesto nacional.</p>
                <p>2. Los recursos provenientes de crédito interno y externo.</p>
                <p>3. Las donaciones que reciba.</p>
                <p>4. Los recursos de cooperación nacional o internacional.</p>
                <p>5. Los demás que obtenga a cualquier título.</p>
                <p>PARÁGRAFO 1. El Consejo Directivo del ICBF definirá cada año cuáles serán los gastos concretos con
                    cargo al fondo tomando en cuenta las condiciones de inversión fijadas en la presente ley. Habrá
                    siempre una apropiación dentro del presupuesto que se le asigne a ICBF para promover educación
                    especial, que les presente nuevas alternativas vocacionales que los oriente hacia un trabajo digno,
                    para los menores objeto de explotación o prácticas sexuales. También se incluirá una apropiación
                    específica para investigar las causas y soluciones del tema que es objeto de la presente ley.</p>
                <p>Las conclusiones de estas investigaciones servirán para definir los programas y proyectos que se
                    ejecutarán en las siguientes vigencias fiscales.</p>
                <p>PARÁGRAFO 2. El ordenador del gasto será el mismo ordenador del ICBF.</p>
                <p>PARÁGRAFO 3. &lt;Parágrafo subrogado por el artículo 21 de la Ley 1336 de 2009. El nuevo texto es el
                    siguiente:&gt; Corresponde al ICBF elaborar anualmente el proyecto de presupuesto del Fondo de que
                    trata el presente artículo, que deberá remitirse al Gobierno Nacional, quien deberá incorporarlo en
                    el proyecto de ley anual de presupuesto. Esta responsabilidad se asumirá conjuntamente con el
                    Ministerio de la Protección Social y el apoyo de la Comisión Interinstitucional integrada por las
                    agencias oficiales responsables de la aplicación de la Ley 679.</p>
                <p>Cada año, simultáneamente con la adjudicación de la ponencia del Proyecto de Ley Anual de
                    Presupuesto, la Mesa Directiva de la comisión o comisiones constitucionales respectivas, oficiarán
                    al ICBF para que se pronuncie por escrito sobre lo inicialmente propuesto al Gobierno y lo
                    finalmente incorporado al proyecto de ley anual. El informe será entregado de manera formal a los
                    ponentes para su estudio y consideración.</p>
                <p>Los Secretarios de las Comisiones Constitucionales respectivas tendrán la responsabilidad de hacer
                    las advertencias sobre el particular.</p>
                <p>Notas de Vigencia<br/>
                    Legislación Anterior<br/>
                    PARÁGRAFO 4. El Gobierno reglamentará lo relacionado con las funciones y responsabilidades de la
                    Junta Directiva del ICBF y del ordenador del gasto en relación con el Fondo cuenta, mientras que el
                    control interno y fiscal deberá adelantarse de acuerdo con las normas constitucionales y legales
                    vigentes.</p>
                <p>PARÁGRAFO 5. Los recaudos a los que hacen referencia los artículos 22 y 23 de la presente ley, se
                    destinarán específicamente a los fines previstos en este estatuto.</p>
                <p>Concordancias<br/>
                    CAPITULO VI.</p>
                <p>MEDIDAS POLICIVAS.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 25. VIGILANCIA Y CONTROL POLICIVO. La Policía Nacional tendrá, además de las funciones
                    asignadas constitucional y legalmente, las siguientes:</p>
                <p>1. Adelantar labores de vigilancia y control de los establecimientos hoteleros o de hospedaje,
                    atractivos turísticos y demás lugares que, a juicio del ICBF, del Ministerio de Desarrollo Económico
                    y de la propia Policía Nacional merezcan una vigilancia especial por existir indicios de explotación
                    sexual de menores de edad.</p>
                <p>2. Apoyar las investigaciones administrativas adelantadas por el Ministerio de Desarrollo Económico
                    en cumplimiento de esta ley.</p>
                <p>3. Canalizar las quejas que se presenten en violación a lo dispuesto en la presente ley.</p>
                <p>4. Inspeccionar e inmovilizar los vehículos en zonas turísticas cuando existan indicios graves de que
                    se utilizan con fines de explotación sexual de menores de edad. Dichos vehículos podrán ser
                    secuestrados y rematados para el pago de las indemnizaciones que se causen por el delito cuya
                    comisión se establezca dentro del respectivo proceso penal.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 26. &lt;Artículo derogado por el artículo 242 de la Ley 1801 de 2016. Rige a partir del 29
                    de enero de 2017&gt;</p>
                <p>Notas de Vigencia<br/>
                    Legislación Anterior<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 27. LÍNEA TELEFÓNICA DE AYUDA. La Policía Nacional, en un término no mayor a quince (15)
                    días contados a partir de la vigencia de la presente ley, en todos los niveles territoriales,
                    designará una línea exclusiva de ayuda para los menores de edad que sean objeto de maltrato o abuso
                    sexual y para recibir denuncias de actos de abuso sexual con menores de edad, o de generación,
                    comercialización o distribución de materiales como textos, documentos, archivos o audiovisuales con
                    contenido pornográfico de menores de edad.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 28. CAPACITACIÓN AL PERSONAL POLICIAL. La Policía Nacional dictará periódicamente cursos y
                    programas de capacitación, con el fin de actualizar al personal policial sobre la legislación
                    vigente en materia de explotación sexual de menores de edad, venta y tráfico de niños, pornografía
                    con menores de edad y atención menores de edad con necesidades básicas totalmente insatisfechas. El
                    Inspector General de la Policía Nacional y el Comisionado Nacional para la Policía realizará los
                    controles necesarios para asegurar el cumplimiento de esta función, sin perjuicio de la vigilancia
                    que corresponde a los organismos de control.</p>
                <p>PARÁGRAFO. El Instituto Colombiano de Bienestar Familiar y las demás entidades públicas, en todos los
                    niveles territoriales, cuyas funciones estén relacionadas con la protección de menores de edad,
                    contribuirán a la capacitación de los miembros de la Policía Nacional.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 29. REGISTRO DE MENORES DESAPARECIDOS. La Policía Nacional llevará un registro de menores
                    de edad desaparecidos, en relación con los cuales establecerá prioridades de búsqueda y devolución a
                    sus familias. Los niños desaparecidos durante más de tres meses, deberán ser incluidos en los
                    comunicados internacionales sobre personas desaparecidas en la sede de la Interpol.</p>
                <p>Concordancias<br/>
                    Ir al inicio<br/>
                    ARTÍCULO 30. VIGILANCIA ADUANERA. Se prohíbe la importación de cualquier tipo de material
                    pornográfico en el que participen menores de edad o en el que se exhiban actos de abuso sexual con
                    menores de edad. Las autoridades aduaneras dictarán medidas apropiadas con el fin de interceptar
                    esta clase de importaciones ilegales, sin perjuicio de las funciones que debe cumplir la Policía
                    Nacional.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 31. PLANES Y ESTRATEGIAS DE SEGURIDAD. Los gobernadores y alcaldes incluirán medidas de
                    prevención y erradicación de la explotación sexual de menores de edad, la pornografía y el turismo
                    asociado a prácticas sexuales con menores de edad en los planes y estrategias integrales de
                    seguridad de que trata el artículo 20 de la Ley 62 de 1993 y o normas que la modifiquen. El
                    incumplimiento de este deber será sancionado disciplinariamente como falta grave.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 32. COMISIÓN NACIONAL DE POLICÍA. Dos (2) representantes de organizaciones no
                    gubernamentales colombianas, cuyo objeto social comprenda la protección y defensa de menores de
                    edad, tendrán asiento en la Comisión Nacional de Policía y Participación Ciudadana.</p>
                <p>CAPITULO VII.</p>
                <p>MEDIDAS PENALES.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 33. ADICIÓNASE EL ARTÍCULO 303 DEL CÓDIGO PENAL CON EL SIGUIENTE INCISO. “Si el agente
                    realizare cualquiera de las conductas descritas en este artículo con personas menores de catorce
                    años por medios virtuales, utilizando redes globales de información, incurrirá en las penas
                    correspondientes disminuidas en una tercera parte.”</p>
                <p>PARÁGRAFO TRANSITORIO. Tan pronto como entre en vigencia la Ley 599 de 2000 el presente artículo
                    tendrá el número 209.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 34. Adiciónase un nuevo artículo al Código Penal, con el número 312A, del siguiente tenor:
                </p>
                <p>Artículo 312A. Utilización o facilitación de medios de comunicación para ofrecer servicios sexuales
                    de menores. El que utilice o facilite el correo tradicional, las redes globales de información, o
                    cualquier otro medio de comunicación para obtener contacto sexual con menores de dieciocho (18)
                    años, o para ofrecer servicios sexuales con éstos, incurrirá en pena de prisión de cinco (5) a diez
                    (10) años, y multa de cincuenta (50) a cien (100) salarios mínimos legales mensuales vigentes.</p>
                <p>Las penas señaladas en el inciso anterior se aumentarán hasta en la mitad (1/2) cuando las conductas
                    se realizaren con menores de doce (12) años.</p>
                <p>PARÁGRAFO TRANSITORIO. Tan pronto como entre en vigencia la Ley 599 de 2000, el presente artículo
                    tendrá el número 219A.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 35. Adiciónase un nuevo artículo al Código Penal, con el número 312B, del siguiente tenor:
                </p>
                <p>Artículo 312B. Omisión de denuncia. El que, por razón de su oficio, cargo, o actividad, tuviere
                    conocimiento de la utilización de menores para la realización de cualquiera de las conductas
                    previstas en el presente capítulo y omitiere informar a las autoridades administrativas o judiciales
                    competentes sobre tales hechos, teniendo el deber legal de hacerlo, incurrirá en multa de diez (10)
                    a cincuenta (50) salarios mínimos legales mensuales vigentes.</p>
                <p>Si la conducta se realizare por servidor público, se impondrá, además, la pérdida del empleo.</p>
                <p>PARÁGRAFO transitorio. Tan pronto como entre en vigencia la Ley 599 de 2000, el presente artículo
                    tendrá el número 219B.</p>
                <p>CAPITULO VIII.</p>
                <p>DISPOSICIONES FINALES.</p>
                <p>Ir al inicio<br/>
                    ARTÍCULO 36. INVESTIGACIÓN ESTADÍSTICA. &lt;Artículo modificado por el artículo 13 de la Ley 1336 de
                    2009. El nuevo texto es el siguiente:&gt; Con el fin de producir y difundir información estadística
                    sobre la explotación sexual de niños, niñas y adolescentes, así como unificar variables, el DANE
                    explorará y probará metodologías estadísticas técnicamente viables, procesará y consolidará
                    información mediante un formato único que deben diligenciar las organizaciones gubernamentales y no
                    gubernamentales, y realizar al menos cada dos años investigaciones que permitan recaudar información
                    estadística sobre:</p>
                <p>– Magnitud aproximada de los niños, niñas y adolescentes menores de 18 años explotados sexual y
                    comercialmente.</p>
                <p>– Caracterización de la población menor de 18 años en condición de explotación sexual comercial.</p>
                <p>– Lugares o áreas de mayor incidencia.</p>
                <p>– Formas de remuneración.</p>
                <p>– Formas de explotación sexual.</p>
                <p>– Factores de riesgo que propician la explotación sexual de los menores de 18 años.</p>
                <p>– Perfiles de hombres y mujeres que compran sexo y de quienes se encargan de la intermediación.</p>
                <p>El ICBF podrá sugerir al DANE recabar información estadística sobre algún otro dato relacionado con
                    la problemática. Los gobernadores y alcaldes distritales y municipales, así como las autoridades
                    indígenas, prestarán su concurso al DANE para la realización de las investigaciones.</p>
                <p>Toda persona natural o jurídica de cualquier orden o naturaleza, domiciliada o residente en
                    territorio nacional, está obligada a suministrar datos al DANE en el desarrollo de su investigación.
                    Los datos acopiados no podrán darse a conocer al público ni a las entidades u organismos oficiales,
                    ni a las autoridades públicas, sino únicamente en resúmenes numéricos y/o cualitativos, que impidan
                    deducir de ellos información de carácter individual que pudiera utilizarse para fines de
                    discriminación.</p>
                <p>El DANE impondrá sanción de multa de entre uno (1) y cincuenta (50) salarios mínimos legales
                    mensuales vigentes a toda persona natural o jurídica, o entidad pública que incumpla lo dispuesto en
                    esta norma, o que obstaculice la realización de la investigación, previa la aplicación del
                    procedimiento establecido en el Código Contencioso Administrativo, con observancia del debido
                    proceso y criterios de adecuación, proporcionalidad y reincidencia.</p>
            </div>
        </Layout>
    );
}

