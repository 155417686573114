import React from 'react';
import ServicesItemPart from '../../componets/servicesItemPart/Index';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';

class ServicesPage extends React.Component {

    render() { 
        return ( 
          <Layout>
            <div className="page-title-area">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-center">
                      <h2 className="title">Servicios</h2>
                      <nav aria-label="breadcrumb">
                      </nav>
                    </div>
                    {/* page title */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
            {
              /*====== PAGE TITLE PART ENDS ======*/
            }
            <ServicesItemPart/>
            {/*<ServicesPlansPart/>*/}
            </Layout>
         );
    }
}
 
export default ServicesPage;
