import Layout from "../../layouts";
import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import routerUso from '../../assets/images/icomredes/router-uso.jpeg'
import calllerUso from '../../assets/images/icomredes/calller-uso.jpeg'
import notWater from '../../assets/images/icomredes/mantenimiento-preventivo-pc-fusion.jpeg'
import regulador from '../../assets/images/icomredes/regulador-de-voltaje.jpeg'
import cables from '../../assets/images/icomredes/intrapixel-7XaBaKwrV1E-unsplash.jpeg'

function UseRecomendations() {
    return (
        <Layout>
            <div className="page-title-area">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="page-title-item text-center">
                                <h2 className="title">Recomendaciones de uso</h2>
                                <nav aria-label="breadcrumb">

                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link  to={"/"}>Inicio </Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Recomendaciones de uso
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            {/* page title */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="news-area news-area-2">
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-9" style={{height: '380px', marginTop: '10px'}}>
                            <div className="single-news mt-30" style={{height: '100%'}}>
                                <img src={routerUso} alt="" />
                                <div className="single-news-overlay">
                                    <span>No intentar mover cables y/o componentes</span>
                                    <h5 className="title" style={{color: 'white'}}>
                                        NO MANIPULAR EL EQUIPO
                                    </h5>
                                    {/*<Link  to={"/"}>*/}
                                    {/*    <i className="fal fa-long-arrow-right" />*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                            {/* single news */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9" style={{height: '390px', marginTop: '10px'}}>
                            <div className="single-news mt-30" style={{height: '100%'}}>
                                <img src={calllerUso} alt="" />
                                <div className="single-news-overlay">
                                    <span>Si tienes dificultades con los equipos llamar a nuestras lineas de soporte</span>
                                    <h5 className="title" style={{color: 'white'}}>
                                        LLAMAR A SOPORTE
                                    </h5>
                                    {/*<Link  to={"/"}>*/}
                                    {/*    <i className="fal fa-long-arrow-right" />*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                            {/* single news */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9" style={{height: '390px', marginTop: '10px'}}>
                            <div className="single-news mt-30" style={{height: '100%'}}>
                                <img src={notWater} alt="" />
                                <div className="single-news-overlay">
                                    <span>No usar liquidos para limpiar los equipos , lo recomendable es limpiar con paños secos</span>
                                    <h5 className="title" style={{color: 'white'}}>
                                        NO USAR AGUA
                                    </h5>
                                    {/*<Link  to={"/"}>*/}
                                    {/*    <i className="fal fa-long-arrow-right" />*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                            {/* single news */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9" style={{height: '390px', marginTop: '10px'}}>
                            <div className="single-news mt-30" style={{height: '100%'}}>
                                <img src={regulador} alt="" />
                                <div className="single-news-overlay">
                                    <span>Para evitar sobrecarga de energia hacia los equipos</span>
                                    <h5 className="title" style={{color: 'white'}}>
                                        USAR REGULADOR
                                    </h5>
                                    {/*<Link  to={"/"}>*/}
                                    {/*    <i className="fal fa-long-arrow-right" />*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                            {/* single news */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9" style={{height: '390px', marginTop: '10px'}}>
                            <div className="single-news mt-30" style={{height: '100%'}}>
                                <img src={cables} alt="" />
                                <div className="single-news-overlay">
                                    <span>Evitar de que los cables se doblen o se achaten</span>
                                    <h5 className="title" style={{color: 'white'}}>
                                        NO GOLPEAR CABLES
                                    </h5>
                                    {/*<Link  to={"/"}>*/}
                                    {/*    <i className="fal fa-long-arrow-right" />*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                            {/* single news */}
                        </div>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export default UseRecomendations;
